// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_sC d_gv d_cs";
export var heroHeaderCenter = "s_gw d_gw d_cs d_dw";
export var heroHeaderRight = "s_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "s_sD d_gr d_cw";
export var heroParagraphCenter = "s_gs d_gs d_cw d_dw";
export var heroParagraphRight = "s_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "s_sF d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "s_sG d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "s_sH d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "s_sJ d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "s_sK d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "s_rF p_rF";
export var heroExceptionNormal = "s_rG p_rG";
export var heroExceptionLarge = "s_rH p_rH";
export var Title1Small = "s_rg p_rg p_qM p_qN";
export var Title1Normal = "s_rh p_rh p_qM p_qP";
export var Title1Large = "s_rj p_rj p_qM p_qQ";
export var BodySmall = "s_rv p_rv p_qM p_q4";
export var BodyNormal = "s_rw p_rw p_qM p_q5";
export var BodyLarge = "s_rx p_rx p_qM p_q6";